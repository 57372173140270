/***************Header and Global*******************/
body{
	background: #f4f4f4 !important;
}

.locked {
	background: white !important;
}

.ui.secondary.inverted.menu a.item:not(.disabled) {
    color: rgb(244, 244, 244)!important;
}
.ui.inverted.menu .item.disabled {
    color: grey !important;
}
.ui.inverted.menu .item.disabled:hover {
    color: grey !important;
}

.subheader{
	font-size: 1.05rem !important;
}

.main-links{
	font-size: 1.1rem !important;
	text-transform: uppercase !important;
}

.panel-bg{
	background: #f4f4f4 !important;
	margin:0px !important;
	padding: 0px !important;
	padding-bottom: 20px !important;
}

.ui.rating .icon:focus{
	outline:none !important;
}

.ui.secondary.inverted.pointing.menu .active.item{
	border-color:#2272ff !important;
	border-width: 5px !important
}
.ui.menu .item, .ui.menu .item .segment{
	font-size: 12px !important;
}
.ui.rating:focus{
	outline: none !important; 
}
.white-color{
	color:#fff !important;
}

.main-header{
	background: #282828 !important;
}
.background{
	 width: 43px;
  height: 38px;
  border-radius: 4px;
  background-color: #2272ff;
  padding: 8px;
  margin-right:7px;
  font-size: 30px;
  color:#fff;
}

.logo{
	margin-top: 6px;
	display: inline-block;
}

.ui.fluid.container{
	max-width: 1440px !important;
}

.white-logo{
	filter:invert(100%);
}

.no-data{
	color: rgba(0, 0, 0, 0.87);
	opacity: 0.2;
	font-size: 35px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.6px;
}

.ui.secondary.inverted.pointing.menu .item{
	font-size: 12px !important;
}

.error-header a{
	color:white !important;
}

.error-header:hover a{
	color: #4183c4 !important;
}

.error-header a:hover{
	color: #4183c4 !important;
}

/***************Filter Header*******************/
.filters{
	background: #585858 !important;
	color:#fff !important;
	padding: 0px 10px;
}
.filters-peer{
	background: #585858 !important;
	color:#fff !important;
	padding: 0px 10px;
}

.filters .container > .ui.grid{
	padding: 20px 20px !important;
}

.input-closed {
	width: 0px;
	display: none !important;
}

.input-open {
	width: 288px !important;
	transition: 0.5s all ;
}

.animating-input{
	transition: 0.5s all;
}

.input-open input{
	transition: all 1s !important;
}

.no-padding{
	padding: 0px !important;
}

.filters .ui.checkbox label{
	color:rgba(255, 255, 255, 0.7) !important;
}

.filter-header{
	background-color: #585858 !important;
	border-bottom: none !important;
}

.industry_names{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}


/***************Login Page*******************/
.login-box{
	margin: auto!important;
	padding: 4rem 2rem !important;
	max-width: 700px;
}

.login-box .ui.form{
	max-width: 400px;
	margin: 0 auto;
}

.login-block{
	padding-bottom:0px;
	padding: 1%;
}

.login-header{
	max-width:1040px;
	margin:0px auto !important;
	padding:17px 0px !important;
}

.login-header .container {
	padding-left: 20px !important;
}
.login-header .item{
	padding: 0px !important;
	font-size: 13px !important;
}
.login-header .item .icon{
	font-size: 1.5em;
	margin: 0px !important
}
.login-header .ui.menu .item>i.icon{

}
.login-banner{
	object-fit: cover;
}


/***************Search Events Page*******************/
.main-section{
	padding: 15px !important;
	display: flex !important;
}
.search-sidebar{
	overflow: scroll;
	width: 100%;
	max-height:1525px;

}

.hidden-text{
 overflow: hidden;
    max-height: 40px;
}

.show-text{
	
}

.side-image{
	max-height: 60px;
}

.shrink{
	transition: width 0.6s;
}

.outer-segment{
	padding: 0px !important;
	margin-top: 34px !important;
}

.expanded{
	min-height: 1590px;
	max-width: 20px !important;
	padding: 1em !important;
}

.expanded i{
	cursor: pointer;
	margin-left:-5px !important;
}

.search-results{
	padding:1em !important;
}

.sidepanel{
	background: #f8f8f8;
	cursor: pointer;
}
.readMoreText{
	color:#5290ff !important;
}
.company-details{
	flex-direction: column;
    height: 100%;
    justify-content: center;
    display: flex;
}

.location{
	border: 1px solid #d0d0d0;
	padding: 15px 9px;
}

.left-panel{
	width: 100% !important;
	position: relative !important;
	box-shadow: none !important 
}

.ui.rating .active.icon{
	color:#5290ff !important;
}

.ui.rating .icon.selected{
	color:#5290ff !important;
}

.sections{
	margin-bottom: 40px;
}

.company-tags{
	background-color: #eaf2ff !important;
	    font-size: 14px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
}

.header-panel{
	background: #eaf2ff !important;

	border-bottom: 3px solid #77a8ff;
	color:#2d4a7d !important;
	font-weight: bold;
	font-size:15px !important;
	border-radius: 0px !important
}
.main-header.menu{
	margin-bottom:0px !important;
}
.ui.segment:not(.get-border-radius){
	border-radius:0px !important;
}



/***************Dashboard Page*******************/

/*.bold-divider{
	border-top: 6px solid rgba(34,36,38,.15) !important;
}*/
/*::-webkit-scrollbar-track{
	    background: transparent !important;
}*/

/*.panel-bg{
		margin-top:-1rem !important;
}*/

.updates-segment .grid .row .column{
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	padding-top: 1.8rem !important;
	padding-bottom: 1.8rem !important;
}
.updates-segment .grid .row{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.update-list .update-date{
	background-color:#f8f8f8;
}
.update-list{
	font-size:16px;
}
.conferences, .comparison-segment{
	padding: 2rem !important;
}
.venue-segment{
	background: #eaf2ff !important;
	min-height: 110px;
	max-height:110px;
	/*width: 288px !important;*/
 	/*height: 64px !important;*/
 	border: solid 1px #c4daff !important;
}
/*.venue-segment .header{
	margin:0px !important;
}*/
.conferences .venue-segment .divider{
	margin:0.8rem !important;
}
.conferences h5{
	line-height: 20px !important;
	margin:0.5rem !important;
}
.conferences .divider{
	margin:0.5rem !important;
	border-color:#c4daff !important;
	/*color:#c4daff !important;*/
	background-color: #c4daff !important;
}
.participation .row{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.part-value{
	font-weight:700;
}
.industry-screen .content, .industry-screen .actions, .welcome-screen .content, .welcome-screen .actions{
	text-align: center !important;
}

.industry-screen .content, .welcome-screen .content{
	padding:5.5rem !important;
}
.error-msg .content{
	padding: 0px !important;
}
.content p{
	font-size:16px !important;
}
.ui.modal{
	border-radius:0px !important;
}
.modal span{
	font-weight:bold;
}
.industry-screen .row{
padding: 0.5rem;}

/***************Account profile ******************/

.dropdown.icon{
	display:none;
}
.year-dropdown .dropdown.icon{
	display: inline !important;
}
/*.ui.secondary.inverted.pointing.menu .active.item.dropdown{
	border-color: transparent !important;
}*/
.account-segment{
	padding:4rem !important;
}
.inverted .menu{
	margin-top:10px !important;
}
.account-segment label{
	color:#585858 !important;
}
.account-segment{
	margin-top: 50px !important;
}
.industry-screen .industry-head{
	padding:0px 20px !important;
}
.account-segment .button{
	font-size:12px !important;
}
.button-grid .column .button{
	padding-top:15px !important;
	padding-bottom:15px !important;
}


/****************My-Event Page*******************/
.my-event-buttons{
	width:170px !important;
	margin:10px !important;
	font-size: 12px !important;
	height:45px !important;
}
.white-btn{
	background: #ffffff !important;
}
.fc-toolbar.fc-header-toolbar,.ui.table .table-head tr{
	background-color: #cce2ff;
	border:solid 1px #5290ff;
	margin-bottom: 0px !important;
	padding-top:5px;
	padding-bottom:5px;
}
.ui.table .table-head tr th{
	background-color: #cce2ff!important;
}

/****Summary Section****/
.summary-segment{
	border-radius:0px !important;
}
.conf-name{
	background-color:#f8f8f8;
	font-weight:700;
}
.conf-desc{
	font-weight:700;
}
.description-grid .row{
	padding-top:5px !important;
	padding-bottom:5px !important;
}
.summary-div{
	overflow-x: auto;
	overflow-y:hidden;
	display:flex;
	/*flex-direction: column;*/
	/*max-width:1200px;*/
}
/*.description-grid .row:last-child{
	display:inline-block;
	white-space: nowrap;
}*/
.summary-table{
	white-space: nowrap;
}
.starred{
	border-right:solid 1px rgb(0,0,0,0.1);
	padding-right:2.2rem !important;
	padding-top:2rem !important;
	padding-bottom:2rem !important;
}
.summary-table .ui.grid>.column:not(.row) {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.summary-table tbody tr,.summary-table tbody tr td{
	height:75px !important;
}
.summary-segment{
	padding:0px !important;
	margin-top:30px !important;
	margin-bottom: 30px !important;
}
/****Price Comparison Section****/
.comparison-segment .comp-grid .column{
	width:140px !important;
}
.comparison-grid{
	overflow-x: auto;
	/*width:1127px;*/
	overflow-y:hidden;
	/*white-space: nowrap;*/
	display:flex;
	/*max-height:1120px;*/
}
.comparison-segment .venue-segment .divider{
	margin:0.4rem !important;
}
.comparison-columns{
	display:inline-block;
	/*width:400px !important;*/
	/*height: 1200px;*/
}
.comparison-segment:first-child{
	margin-top: 1rem !important;
	margin-left: 0px !important;
}
.comparison-segment:first-last{
	margin-bottom: 1rem !important;
}
.comparison-segment{
	margin:1rem 1rem !important;
}
.comparison-segment .venue-segment .header{
	margin:0px !important;
}
.comparison-segment .divider{
	margin:0.5rem !important;
	border-color:#c4daff !important;
	/*color:#c4daff !important;*/
	background-color: #c4daff !important;
}
.comparison-segment .section-divide{
	margin-top:20px !important;
	margin-bottom:20px !important;
}
::-webkit-scrollbar-track{
	    background: transparent !important;
}
::-webkit-scrollbar-thumb{
	background:white !important;
	border: solid 1px rgba(0,0,0,0.2);
}
/****Calendar Section****/
.fc-event-container{
	cursor: pointer !important;
}
.fc-toolbar.fc-header-toolbar h2{
	display:inline-block;
	font-size:14px;
}
.fc-day-grid-event .fc-content{
	white-space: normal !important; 
}

.fc-button-primary{
	background-color: transparent !important;
	border-color:transparent !important;
	color:black !important;
	padding-bottom: 14px !important;
}
.fc-button-primary:focus{
	box-shadow: none !important
}

.fc-day-grid-event.fc-h-event.fc-event{
border:0px;
border-left:solid 5px;
border-radius: 0px;
font-size:13px;
padding:7px;
}
.calendar-segment{
	padding:0px !important;
	margin-top:30px !important;
	margin-bottom: 30px !important;
}
.calendar-container,.calendar-segment{
	height:800px;
}
/*.tooltip{
	background-color: yellow;
	z-index: 5;
	width:200px;
	text-align: center;
	padding:10px;
}*/
.EventModal .header,.EventModal .content{
	text-align:center;
}
.react-add-to-calendar__dropdown ul{
	list-style:none;
	text-align:left;
	padding:0px !important;
}
.react-add-to-calendar__button{
	cursor: pointer;
}
.add-to-calendar-button .dropdown.icon{
display:none;
}
.add-to-calendar-button .calendar.icon{
	margin:0 3px !important;
}
.add-to-calendar-button .menu{
	width:97%;
}
.info-text{
	font-style: italic;
	opacity: 0.3;
}

/***************Compare Peer ******************/
.compare-peers-header{
text-align: center;
vertical-align: middle;
line-height: 70vh; 
}



.compare-peers-header span{
	
  opacity: 0.04;
  
  font-size: 55px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.87);
}

.add-peers-box{
	width: 70%;
	background: #444444 !important;
}

.add-peers-box .label{
	padding: 7px !important;
}

#print-table{
	overflow-x:auto;
}
.locked-rows .ui.placeholder{
	animation: none !important;
}


/* Help Page */
.topic-desc{
	margin-top: 15px
}
.desc{
	padding-left: 20px;
}
.topic-details{
	padding-left: 40px;
}
.help-section{
	margin: 5em 2em !important;
}
.help-column{
	padding: 2em !important;
}
.ui.table thead tr:first-child > th {
     position: sticky !important;
     top: 0;
     z-index: 2;
}
#table-fixed{
	max-height: 800px;
	display: block;
	overflow: hidden;
	overflow-y: auto !important;
}
.rating-speaker{
	width: auto !important;
}
/* ********* */


/***************Responsive Queries *******************/

@media (max-width: 769px) {
		.login-banner{
		height: 110px;
		}
		.search-sidebar{
		max-height: 320px !important
		}
		.topic-details{
		padding-left: 20px;
		}
		.account-segment{
		padding:1rem !important;
		}
		.help-section{
		margin: 5em 0em !important;
		}
		.help-column{
		padding: 1em !important;
		}
		.login-box{

		padding: 4rem 0rem !important;
		}
		.main-header.menu{
		border-radius: 0px !important
		}
		.main-header .menu a.item{
		background: #282828 !important;
		}
		.main-header.menu a.ui.image {
		width: 250px !important
		}
		.mobile-side.ui.menu .item{
		font-size: 15px!important;
		margin: 2em auto!important;
		}
		.login-block{
		padding:3% 0 !important;
		}
		.main-section{
		padding: 0px !important;
		}
		.overflow-mobile{
			width: 100%;
			overflow: auto;
		}
		.speaker-chart-grid{
			width: 400vw;
		}
}

/*@media (min-width: 768px) and (max-width: 1025px){
		.speaker-chart-column{
			width: 76%;
		}
}*/


/****Summary Section****/

.ui.table .table-peers-head  {
    background: #cce2ff !important;
    border:solid 1px #5290ff;
    margin-bottom: 0px !important;
    padding-top:5px;
    padding-bottom:5px;
}

.ui.table .table-peers-head th{
	background-color: #cce2ff !important;
	font-weight: 700 !important;
}
.conf-name{
    background-color:#f8f8f8;
    font-weight:700;
}
.ui.fluid.container{
	padding: 0em 1em;
}
.ui.container {
    padding: 0 1em;
}
.ui.inverted.segment{
	border: 1px solid rgba(34,36,38,.15) !important;
}